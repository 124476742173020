import React from "react";
import "./header.css";
import { CircularRevealPanel, isOpened, setOpened } from "react-circular-reveal";
import img1 from '../../assets/LOGOinO.svg'
import './head'

const header = () => {
  return (
    <section id="home" className="head__sec">
        <div className="loader" id="loader" >
        </div>
        <div className="wrapper ">
          <ul className="dynamic-txts ">
            <li> <span> Necesario</span> </li>
          </ul>
        </div>

        <div className="page2" id="page2">
          <div className="container__color  bod header__container">
            <div className="wrapper1">
              <ul className="dynamic-txts1">
                <li> <span className="c2 t1"> Increase your business 10X </span> </li>
                <li> <span className="c2 t2"> Interacting with the snapper is <b>
                NEW</b></span> </li>
              </ul>
            </div>
            <div>
               {/* <div>
              <p className="static__txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere expedita nostrum nesciunt, earum laborum nemo!</p>
            <p className="static__txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque cumque doloribus molestias iste dolorem magni nihil accusamus eos impedit et, ipsam amet neque totam animi accusantium modi illum quidem quisquam.</p>
              </div>
              <div>
              <img src={img1} className="logo__class"></img>
              </div>  */}

              <div className='container about__container'>

    <div className="about__content">
        <div className="about__cards">
        </div>
        <p className="p1">Combining creativity and technology to revolutionise the photography experience of the world.
          </p>
          <p className="p2"> Necesario's sole purpose is to enhance the photo capturing experience of the world by creating unique and visionary electronic products. We aim to help the people capture emotions through creativity and technology. 
</p>
<p className="p3">If you are ready to join a diverse team which keeps innovation at its forefront to change lives. Then, connect with us and we can revolutionise the photo capturing experience of the world forever.</p>
      </div>

      <div className='about__me' >
        <img src={img1} alt="About Image" />
      </div>
    </div>
    
            </div>
        </div>
      </div>
    </section>
  );
};
export default header;
