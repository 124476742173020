import React from 'react'
import './founder.css'
import CEO from '../../assets/CEO.svg'
import img1 from '../../assets/1.png'
import img2 from '../../assets/2.png'
import img3 from '../../assets/3.png'


const Founder = () => {
  return (
    <section className='fsec' id='experience'>
      <h5> Company's Hero</h5>
      <h2>Founders</h2>
      {/* <div className="banner1">
    <div className="founder1">
      <img src={img1} className="img1"></img>
      <p id="img__txt1">Kamalesh Dwivedi <br></br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Advisor</b> </p>
    </div>
    <div className="founder1" id="middleimg">
      <img src={img3} className="img2"></img>
      <p id="img__txt2" className='txt2__founder'>Imtiyaz Ansari<br></br><b>Founder & CEO</b></p>
    </div>
    <div className="founder1">
      <img src={img2} className="img3"></img>
      <p id="img__txt3">Niraj Trivedi <br></br>
&nbsp;&nbsp;&nbsp;<b>Advisor</b></p>
    </div>
  </div> */}

  <div className="container portfolio__container ">
        <article className='portfolio__item'>
          <div className="portfolio__itm-image founder1">
            <img src={img2} alt="" / >
          </div>
          <h3 className='fName'>Kamalesh Dwivedi - Advisor</h3>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__itm-image founder1">
            <img src={img3} alt="" / >
          </div>
          <h3 className='fName'>Imtiyaz Ansari - Founder & CEO</h3>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__itm-image founder1">
            <img src={img1} alt="" / >
          </div>
          <h3 className='fName'>Niraj Trivedi - Advisor</h3>
        </article>
        </div>
    </section>
  )
}

export default Founder
