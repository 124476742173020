import React from 'react'
import './about.css'
const About = () => {
  return (
    <section id='about' className='sec'>
      <h5> Get To Know </h5>
        <h2> About Us </h2>
      {/* <h5> Get To Know </h5>
        <h2> About Us </h2>
        <div className='outerForAmount banner'>
          <marquee className='banner' direction="left" scrollamount="7" > Who</marquee>
          <marquee className='banner' direction="right" scrollamount="8"> We</marquee>
          <marquee className='banner' scrollamount="7"> Are?</marquee>
        </div>

        <div className='outerForAmount'>
        <div className='txt'>
          Necesario's sole purpose is to enhance the photo capturing experience of the world by creating unique and visionary electronic products. We aim to help the people capture emotions through creativity and technology.
          </div>
        </div> */}
    {/* <div className="container marquee__txts">
      <marquee className="marquee__txt" direction="left" scrollamount="7" id="m1"> Who </marquee>
      <marquee className="marquee__txt" direction="right" scrollamount="7" id="m2">we</marquee>
      <marquee className="marquee__txt" scrollamount="7" id="m3" > Are? </marquee>
    </div>   */}
    <div className='outerForHead'>
      <div className='container__about '>
            <marquee className='banner demo' direction="left" scrollamount="7"> Who</marquee>
            <marquee className='banner demo' direction="right" scrollamount="8"> We</marquee>
            <marquee className='banner demo' scrollamount="7"> Are?</marquee>
      </div>
      <div className="container__about"> 
        <div className="txt txt1">Necesario's sole purpose is to enhance the photo capturing experience of the world by creating unique and visionary electronic products. We aim to help the people capture emotions through creativity and technology.</div>
        <div className="txt txt2">Our product has unique characteristics which has placed us in a position to serve businesses from myriad industries such as  entertainment industry, food and beverages, automobile etc...</div>
        <div className="txt txt1">If you are ready to join a diverse team which keeps innovation at its forefront to change lives. Then, connect with us and we can revolutionise the photo capturing experience of the world forever. </div>
        </div>
       </div>
    </section>
  )
}

export default About
