function appearP1() {
    let l = document.getElementById("loader");
    let p1 = document.getElementById("page1");
    let p2 = document.getElementById("page2");
    l.style.left = "0";
    setTimeout(function() {
      l.style.left = "-100%";
      p2.style.display = "block";
      p1.style.display = "none";
    }, 1000);
  }
  setTimeout(appearP1, 3200);
  