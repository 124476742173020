import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'> Necesario Innovation </a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Founder</a></li>
        <li><a href="#services">Testimonial</a></li>
        <li><a href="#portfolio">Partners</a></li>
      </ul>
      <div className="footer__social">
        <a href="https://www.linkedin.com/company/necesario-innovations/mycompany/" target='_blank'> <BsLinkedin/> </a>
        {/* <a href="" target='_blank'> <BsInstagram/> </a> */}
        <a href="https://twitter.com/ConnectSnapper" target='_blank'> <BsTwitter/> </a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Necesario Innovation. All rights reserved </small>
      </div>
    </footer>
  )
}

export default Footer
