import React from 'react'
import Nav from './components/nav/Nav'
import Footer from './components/footer/Footer'
import Contact from './components/contact/Contact'
import About from './components/about/About'
import Header from './components/header/Header'
import Founder from './components/founder/Founder'
import Company from './components/company/Company'
import Testimonial from './components/testimonial/Testimonial'
import Testi  from './components/testi/Testi'




const App = () => {
  return (
    <div className='outerMain'>
      <Header/>
      <Nav/>
      <About/>
      <Founder/>
      {/* <Testimonial/> */}
      <Testi/>
      <Company/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default App
