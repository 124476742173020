import React from 'react'
import './testi.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'

import img1 from '../../assets/v.png'
import img2 from '../../assets/r.png'
const Testi = () => {
  return (
    <section id='services'> 
    <h5>share Your Idea</h5>
    <h2>Testimonials</h2>
    <div className="container experience__container">
      <div className="experience__frontend">
        <div className='rupani__txt'>
        <h3 className='vr__txt'>Vijay Rupani </h3>
        <h3>Former Cheif Minister of Gujarat </h3>
        <h3>"Very Good innovation for the society, keep it up"</h3>
        </div>
      </div>
      <div className="experience__backend">
      <img src={img1}></img>
      </div>

      <div className="experience__frontend">
        <img src={img2}></img>
      </div>
      <div className="experience__backend">
        <div className='rashmi__txt'>
        <h3 className='rb__txt'> Rashmi Bansal </h3>
      <h3>Indian Fiction Author</h3>
      <h3>"Nice concepts, This is really going help people for amazing photography"</h3>

        </div>
      </div>
    </div>

    </section>
  )
}

export default Testi