import React from 'react'
import './company.css'
import img1 from '../../assets/ICGG.png'
import img2 from '../../assets/META.png'
import img3 from '../../assets/MM.png'
import img4 from '../../assets/DPIT.png'
import img5 from '../../assets/IPI.png'
import img6 from '../../assets/EDII.png'
import img7 from '../../assets/MakerFair.png'
import img8 from '../../assets/FORTUNE1.png'
import img9 from '../../assets/IITGN.png'
import img10 from '../../assets/HalfStory1.png'
import img11 from '../../assets/makerfest.png'


const Company = () => {
  return (
    <section>
        <h2>Our Partners</h2>
  <div class="partners">
    <div>
      <img src={img1}></img>
    </div>
    <div>
      <img src={img2}></img>
    </div>
    <div>
      <img src={img3}></img>
    </div>
    <div>
      <img src={img4}></img>
    </div>
    <div>
      <img src={img5}></img>
    </div>
  </div>
  <h2>Who Trusted US</h2>
  <div class="trust">

    <div>
      <img src={img6}></img>
    </div>
    <div>
      <img src={img7}></img>
    </div>
    <div>
      <img src={img8}></img>
    </div>
    <div>
      <img src={img9}></img>
    </div>
    <div>
      <img src={img10}></img>
    </div>
    <div>
      <img src={img11}></img>
    </div>
    </div>
    </section>
  )
}

export default Company